<template>
  <Header v-if="footerheader_show"/>
  <router-view></router-view>
  <Footer v-if="footerheader_show"/>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data(){
      return {
        footerheader_show:true,
        hideFHPath: [
          '/privacy',
          '/terms'
        ]
      }
  },
  methods:{
    judgment(paths, list) {
      let booleanvalue = list.some((item) => {
        return item === paths
      })
      this.footerheader_show = !booleanvalue;
    }
  },
  watch: {
    $route(to) {
      this.judgment(to.path, this.hideFHPath)   
    }
    
  }
}
</script>

<style>
@import './style/base.css';
#app {
  font-family: Avenir, Helvetica, Arial, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

</style>
