<template>
  <div class="footer">
    <div class="title">随时与我们取得联系</div>
    <img src="@/assets/home/icon_hu.png" />

    <div class="content">
      <img class="contentImg" src="@/assets/home/img_ditu.png" />
      <div class="text">
        <!-- <span class="textSpan"
          >您可以留下您的联系方式，我们的工作人员会马上联系您</span
        >
        <div class="inputArea">
          <div class="inputAreaUp">
            <input
              type="text"
              placeholder="姓名："
              v-model="form.name"
              @input="input($event.target.value, 'name')"
            />
            <input
              type="text"
              placeholder="电话："
              v-model="form.phone"
              @input="input($event.target.value, 'phone')"
            />
          </div>
          <div class="inputAreaDown">
            <textarea
              placeholder="留言："
              v-model="form.context"
              @input="input($event.target.value, 'context')"
            />
            <div class="button" @click="submit">提交</div>
          </div>
        </div> -->
        <div class="info">
          <div class="infoText">
            <div class="infoItem">
              <img src="@/assets/home/icon_dizhi.png" /><span
                >地址：上海市浦东新区郭守敬路498号18号楼1楼</span
              >
            </div>
            <div class="infoItem">
              <img src="@/assets/home/icon_phone.png" /><span
                >电话：18917892540</span
              >
            </div>
            <div class="infoItem">
              <img src="@/assets/home/icon_email.png" /><span
                >邮箱：support@duoshouji.com</span
              >
            </div>
          </div>
          <div class="infoPic">
            <div class="infoPicItem">
              <img src="@/assets/home/img_lxkefu.png" />
              <span>联系客服</span>
            </div>
            <div class="infoPicItem">
              <img src="@/assets/home/img_guanzhu.png" />
              <span>关注公众号</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="copy-right">
      © Copyright
      <a rel="nofollow" href="http://duoshouji.com">duoshouji.com</a>.
      上海始而信息科技有限公司 All right reserved.
      <a href="http://beian.miit.gov.cn" target="_blank">沪ICP备16034928号-1</a>
    </p>
  </div>
</template>

<script>
import { reactive } from "vue";
export default {
  name: "Footer",
  props: {
    msg: String,
  },
  setup() {
    const form = reactive({
      name: "",
      phone: "",
      context: "",
    });
    return {
      form,
    };
  },
  methods: {
    input(value, key) {
      this.form[key] = value;
    },
    submit() {
      const that = this;
      fetch(
        `http://restapi.share68.com/system/sendContactUs?name=${this.form.name}&phone=${this.form.phone}&message=${this.form.context}`,
        { method: "POST" }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (res) {
          if (res && res.resultCode === 0) {
            that.form.name = "";
            that.form.phone = "";
            that.form.context = "";
            alert("提交成功，等待客服回复");
          }
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  background-image: url(../assets/home/img_qudelianxi.png);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 7.68rem;
  background-size: cover;
}
.title {
  font-size: 28px;
  color: #ffffff;
  margin-top: 1.23rem;
}
.content {
  display: flex;
  margin-top: 1.19rem;
  flex-direction: row;
  justify-content: start;
}
.contentImg {
  width: 5.29rem;
  height: 2.9rem;
}
.text {
  /* background-color: #fff; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-left: 0.41rem;
}
.textSpan {
  font-size: 18px;
  color: #ffffff;
}
.inputArea {
  margin-top: 0.22rem;
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-direction: column;
}
.inputArea input {
  width: 2.15rem;
  height: 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  outline: 0;
  border: none;
  padding-left: 10px;
}
.inputAreaUp {
  height: 70px;
  display: flex;
}
.inputAreaDown {
  display: flex;
  align-items: flex-end;
}
.inputAreaDown textarea {
  width: 4.5rem;
  border-radius: 3px;
  height: 60px;
  padding: 0.1rem;
}
.button {
  width: 1rem;
  height: 0.46rem;
  font-size: 18px;
  color: #ffffff;
  line-height: 0.46rem;
  border-radius: 0.23rem;
  background-image: linear-gradient(#fc6144, #e3190b);
  margin-left: 10px;
}
.info {
  margin-top: 0.4rem;
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.infoText {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.infoItem {
  display: flex;
  flex-direction: row;
  justify-content: start;
  height: 0.2rem;
  margin-bottom: 0.2rem;
}
.infoItem > img {
  width: 0.21rem;
  height: 0.21rem;
}
.infoItem > span {
  margin-left: 8px;
  font-size: 16px;
  line-height: 0.2rem;
  height: 0.2rem;
  color: #ffffff;
}
.infoPic {
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-left: 1.39rem;
}
.infoPicItem > img {
  height: 1.04rem;
}
.infoPicItem > span {
  font-size: 16px;
  color: #ffffff;
  margin-top: 5px;
}
.infoPicItem {
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-right: 0.46rem;
}
.copy-right {
  display: inline;
  float: left;
  text-align: center;
  width: 100%;
  color: #fff;
  font-size: 16px;
  margin-top: 40px;
}
.copy-right a {
  color: #fff;
  text-decoration: none;
}
</style>