const routes = [
  {
    path:'/about',
    name:'about',
    component:()=>import('@/views/about')
  },
  {
    path:'/use',
    name:'use',
    component:()=>import('@/views/use')
  },
  {
    path:'/serve',
    name:'serve',
    component:()=>import('@/views/serve')
  },
  {
    path:'/core',
    name:'core',
    component:()=>import('@/views/core')
  },
  {
    path:'/qa',
    name:'qa',
    component:()=>import('@/views/qa')
  },
  {
    path:'/privacy',
    name:'privacy',
    component:()=>import('@/views/privacy')
  },
  {
    path:'/terms',
    name:'terms',
    component:()=>import('@/views/terms')
  },
  {
    path:'/',
    name:'home',
    component: ()=> import('@/views/Home')
  }
]

export default routes;