import { createApp } from 'vue'
import App from './App.vue'
import { createWebHistory, createRouter } from 'vue-router'
import routes from './router'
import './utils/rem'
const VueScrollTo = require('vue-scrollto')


// import 'amfe-flexible/index'

const app = createApp(App)
app.use(VueScrollTo)
const history = createWebHistory()

const router = createRouter({
  history, // 路由模式
  routes
})
app.use(router)

app.mount('#app')



