<template>
  <div class="header">
    <div class="container">
      <img class="logo" src="../assets/home/logo.png">
      <div class="nav">
        <router-link to="/" class="" active-class="selectButton">首页</router-link>
        <router-link to="/core" active-class="selectButton">核心功能</router-link>
        <router-link to="/use" active-class="selectButton">使用场景</router-link>
        <router-link to="/serve" active-class="selectButton">企业服务</router-link>
        <router-link to="/qa" active-class="selectButton">常见问题</router-link>
        <router-link to="/about" active-class="selectButton">关于我们</router-link>
        <!-- <a @click="showQRCode">
          <div class="qrcodec">
            <span>联系客服</span>
            <div v-if="ifShowQRCode" class="qrcode">
              <div class="qrcodeArrow"/>
              <img class="cs" src="../assets/cs.jpg"/>
              <span>扫客服微信</span>
            </div>
          </div>
        </a> -->
      </div>
    </div>
   
  </div>
</template>

<script>
import {ref} from 'vue'
export default {
  name: 'Header',
  props: {
    msg: String
  },
  data() {
    return {
      ifShowQRCode: false
    }
  },
  setup() {
    const read = ref(0)
    // const mypagestyle = reactive({
    //     'height': '90px',
    //     'margin': '0 320px',
    // })
    // let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // let margin = w/1920*320
    // mypagestyle.margin = `0 ${margin}px`
    return{
      read,
      // mypagestyle
    }
  },
  methods: {
    showQRCode(){
      this.ifShowQRCode = !this.ifShowQRCode;
    }
  },
  mounted(){
    document.body.addEventListener('click', ()=>{
      this.ifShowQRCode = false;
    }, true)
  }
  

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .header{
    height: .9rem;
    width: 12.8rem;
    /* padding-left: 3.2rem;
    padding-right: 3.2rem; */
    box-shadow: 0px 8px 20px 0px rgba(255, 0, 0, 0.04);
    margin: 0 auto;
  }
  .container{
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .logo{
    height: .46rem;
    width: 1.54rem;
  }
  .nav{
    /* width: 5.5rem; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .nav >a{
    font-size: .18rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    margin-right: .8rem;
  }
  a {
    text-decoration: none;
    color:#333333;
    font-size: .18rem;
    cursor: pointer;
  }
  a:hover{
    color:red
  }
  .selectButton{
    width: .9rem;
    height: .4rem;
    background-color: #F70D08;
    color: #FFFFFF;
    font-size: .18rem;
    line-height: .4rem;
  }
  .selectButton:hover{
    color:#fff
  }
  .qrcodec {
    position: relative;
  }
  .qrcode {
    background-color: #fff;
    width: 200px;
    height: 200px;
    position: absolute;
    top: 45px;
    left: -60px;
    z-index: 100;
    box-shadow: 0px 5px 28px 2px rgb(0 0 0 / 10%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #333;
    font-size: 16px;
  }
  .qrcodeArrow {
    width: 0;
    height: 0;
    border: 10px solid;
    border-color: transparent transparent #fff;
    position: absolute;
    top: -20px;
    left: 90px;
  }
  .cs {
    width: 150px;
  }
</style>